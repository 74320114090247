import React from 'react'
import { Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ZoomCompact from '@widgets/ZoomCompact'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Commitment from '@widgets/Commitment'

export default () => (
  <>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Connect with Us"
          subheader='If you have any local issues you would like to address,
           or wish to subscribe to our email newsletter please do not hesitate to send us a message below.'
        />
        <Divider />
        {/*<ContactForm />*/}
      </Main>
      <Sidebar>
        {/*<NewsletterCompact />*/}
        <Divider />
        <ZoomCompact />
        <Divider />
        <Commitment />
        
      </Sidebar>
    </Stack>
  </>
)
