
import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
    <Card variant='paper'>
    <Text variant='heading'>Volunteer</Text>
      <Text variant='p'>
        We are an entirely volunteer-based team of Manly locals, and are always 
        interesting in hearing from others who would like to contribute.
      </Text>
    </Card>
 )

export default Commitment
