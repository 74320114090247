import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'
import ZoomForm from '@components/ZoomForm'

const ZoomCompact = props => (
    <Card variant='paper'>
      <Text variant='heading'>Monthly Meetings</Text>
      <Text variant='p'>
       We hold monthly meetings online on the third
       Monday of the month (except January). 
      </Text>
      <Text variant='p'>
       Everyone from Fairy Bower, Little Manly, Isthmus and
       Central Manly is welcome to register. We also welcome others who wish to attend, 
       but request that you get in contact first.
      </Text>
    </Card>
  
)

export default ZoomCompact

//<Section aside title='Monthly Meetings' {...props}>
//</Section><ZoomForm />
